import classNames from 'classnames';
import Image from 'next/legacy/image';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import type { HeadingTag } from '../../components/heading';
import { Container } from '../../components/layout/grid';
import { List, ListIconSize } from '../../components/list';
import { Markdown } from '../../components/markdown';
import { Text } from '../../components/text';
import { TokenIcon } from '../../components/token-icon';
import { Video } from '../../components/video';
import type { SideBySideFragment } from '../../graphql/fragments/SideBySideFragment.graphql';
import { usePersonalizations } from '../../lib/hooks/usePersonalizations';
import { renderTemplate } from '../../lib/utils/text';
import { getAlternatingTheme } from '../../lib/utils/theme';
import variables from '../../styles/variables.module.scss';
import type { VideoPresetType, VideoSourceType } from '../../types/video';
import { ActionButton } from '../action-button';
import { DynamicElementComponent } from '../dynamic-element';
import styles from './side-by-side.module.scss';
const ICON_LIST_THEME_ORDER = ['cyanora', 'coal-mine-canary', 'future-fuscia'];
enum ListColor {
  default = 'default',
  alternating = 'alternating',
}

/**
 * Displays a content block with a title, text, image,and/or link button next to some sort of media, such as a video or image.
 */

export const SideBySide: FunctionComponent<Props> = ({
  anchorId = '',
  heading,
  headingColor,
  copy,
  copyColor,
  supportingCopy,
  supportingCopyColor,
  button,
  secondaryButton,
  image,
  video,
  mediaPosition,
  mediaPositionVertical = 'bottom',
  showIcon = false,
  variant = 'default',
  iconList,
  listColor,
  headingTag = 'h2',
  dynamicElement,
  spacing = 'default',
  backgroundColor = 'black'
}) => {
  const personalizations = usePersonalizations();

  // determine the precedence of which visual item to show
  const visualElement = useMemo(() => {
    if (dynamicElement) {
      return <DynamicElementComponent type={dynamicElement} />;
    }
    if (video) {
      return <Video {...video} preset={video.preset as VideoPresetType ?? 'gif'} poster={video.poster?.source} alt={video.alt as string} sources={video?.files?.items as VideoSourceType[]} className={styles.media} />;
    }
    if (image?.url) {
      return <Image className={styles.media} src={image.url} layout='responsive' width={image.width} height={image.height} alt={image?.alt ?? ''} priority />;
    }
    return null;
  }, [dynamicElement, video, image]);
  return <div style={{
    backgroundColor: variables[backgroundColor]
  }} id={anchorId} data-sentry-component="SideBySide" data-sentry-source-file="side-by-side.tsx">
            <Container className={classNames(styles['container'], styles[`${spacing}-spacing`])} data-sentry-element="Container" data-sentry-source-file="side-by-side.tsx">
                {showIcon && <TokenIcon type='stars' scale={1.5} className={styles[`token-icon`]} color='tertiary' />}
                <div className={styles[`row`]}>
                    <div className={classNames(styles['column'], styles['media-column'], styles[`column-${mediaPosition}`], styles[`column-order-${mediaPosition === 'left' ? 0 : 2}`], styles[`column-order-mobile-${mediaPositionVertical}`])}>
                        <div className={classNames(styles[`column-inner`], styles[`column-inner--${variant}`])}>
                            {visualElement}
                        </div>
                    </div>
                    <div className={classNames(styles['column'], styles['copy-column'], styles[`column-${mediaPosition === 'left' ? 'right' : 'left'}`])}>
                        <div className={classNames(styles[`column-inner`], styles[`column-inner--${variant}`])}>
                            <Markdown tag={headingTag} typeStyle={{
              _: 'heading-md',
              md: 'heading-l'
            }} markdown={renderTemplate(heading, personalizations)} color={headingColor} accentColor='primary' data-sentry-element="Markdown" data-sentry-source-file="side-by-side.tsx" />
                            <div className={styles['copy-container']}>
                                <Markdown typeStyle={{
                _: 'body-lg',
                md: 'body-md'
              }} markdown={renderTemplate(copy, personalizations)} color={copyColor} data-sentry-element="Markdown" data-sentry-source-file="side-by-side.tsx" />
                            </div>
                            {!!iconList?.items?.length && <div className={styles['icon-list-container']}>
                                    <List withCustomIcons>
                                        {iconList.items.map(({
                  sys: {
                    id
                  },
                  icon: {
                    alt = '',
                    source = ''
                  } = {},
                  title,
                  size
                }, index) => <List.Item key={id} icon={{
                  alt,
                  source
                }} className={classNames(size === ListIconSize.heading && styles['icon-list-item--heading'])}>
                                                    <Markdown tag='div' markdown={title} typeStyle={size === ListIconSize.heading ? 'heading-60' : undefined} color={listColor === ListColor.alternating ? getAlternatingTheme(index, ICON_LIST_THEME_ORDER).key : undefined} />
                                                </List.Item>)}
                                    </List>
                                </div>}
                            {button && <div className={styles.buttons}>
                                    {!!button && <ActionButton {...button} />}
                                    {!!secondaryButton && <ActionButton preset='secondary' {...secondaryButton} />}
                                </div>}
                            {supportingCopy && <Text typeStyle={{
              _: 'body-lg'
            }} tag='div' className={styles['supporting-copy']} color={supportingCopyColor}>
                                    {supportingCopy}
                                </Text>}
                        </div>
                    </div>
                </div>
            </Container>
        </div>;
};
export default SideBySide;
type Props = Omit<SideBySideFragment, 'sys' | 'type'> & {
  headingTag?: HeadingTag;
};